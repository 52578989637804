import React, { useState }  from 'react';
import { Grid, Button } from '@mui/material';
import SessionStats from './SessionStats';

const vGradeKeys = ['v1', 'v2', 'v3', 'v4', 'v5', 'v6', 'v7', 'v8', 'v9']

function SessionContainer() {
  const [stats, setStats] = useState<any>({
    v1: 0,
    v2: 0,
    v3: 0,
    v4: 0,
    v5: 0,
    v6: 0,
    v7: 0,
    v8: 0,
    v9: 0
  });

  const handleChange = (grade: string) => {
    let updatedStats = stats;
    updatedStats[grade] = updatedStats[grade] + 1
    let newTotal = stats.total + 1;

    setStats((stats: any) => ({
      ...stats,
      ...updatedStats,
      ...newTotal
    }));
    }

  return (
    <div>
      <h1>Bouldering Session Stats</h1>
      <SessionStats props={stats}/>
      <Grid container spacing={2}>
      {vGradeKeys.map((grade) => (
          <Grid item xs={4} key={grade}>
            <Button
            variant="contained"
            onClick={(e) => {
              handleChange(grade)
            }}
            >{grade}</Button>
          </Grid>
      ))}
      </Grid>
    </div>
  );
}

export default SessionContainer;
