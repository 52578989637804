import React from 'react';

function SessionStats(props: any){

    const vGradesLegend: any = {
        v1: 1,
        v2: 2,
        v3: 3,
        v4: 4,
        v5: 5,
        v6: 6,
        v7: 7,
        v8: 8,
        v9: 9
    };

    const calculateTotal = () => {
        let numbers: any = Object.values(props.props);
        let total = 0;
        for (let i = 0; i < numbers.length; i++) {
            total = total + numbers[i];
        }
        return total;
    }

    const calculateSum = () => {
        let vSum = 0;
        let vGrades = Object.keys(props.props);

        for (let i = 0; i < vGrades.length; i++) {
            let key = vGrades[i];
            let addition = props.props[key] * vGradesLegend[key];
            vSum = vSum + addition;
        }

        return vSum;
    }

    const calculateAvg = () => {
        let vTotal = calculateTotal();
        let vSum = calculateSum();
        let vAvg = vSum / vTotal;

        if (vTotal === 0) {
            return 0
        }

        return Math.round(vAvg);
    }

    return(
        <div className="SessionStats">
            <table>
                <tr>
                    <th className="StatColumn">Total</th>
                    <th className="StatColumn">vSum</th>
                    <th className="StatColumn">vAvg</th>
                </tr>
                <tr>
                    <td className="StatColumn">{calculateTotal()}</td>
                    <td className="StatColumn">{calculateSum()}</td>
                    <td className="StatColumn">{calculateAvg()}</td>
                </tr>
            </table>
        </div>
    );
}

export default SessionStats;
